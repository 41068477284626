/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react';
import './Packages.css';
import FuseAnimate from '@fuse/core/FuseAnimate';
import { InputAdornment, IconButton, TextField, CircularProgress, makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import * as Actions from 'app/store/actions';
import { getPackages } from 'app/services/Packages/packages';
import history from '@history';
import FusePageSimple from '@fuse/core/FusePageSimple';

const useStyles = makeStyles({
	layoutRoot: {},
	sidebar: {
		width: 330,
	},
});

function Packages() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [packages, setPackages] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [filters, setFilters] = useState({ search: '' });
	const [firstLoad, setFirstLoad] = useState(true);

	const handleFilters = (ev) => {
		if (ev.target.name === 'search') {
			setFilters({ ...filters, search: ev.target.value });
		}
	};

	useEffect(() => {
		let isMounted = true;
		const timeout = setTimeout(
			() => {
				if (!isMounted) return;
				setIsLoading(true);
				getPackages(filters.search)
					.then((res) => {
						if (!isMounted) return;
						setFirstLoad(false);
						setPackages(res.data);
					})
					.catch((err) => {
						if (!isMounted) return;
						dispatch(
							Actions.showMessage({
								message: 'Failed to fetch data, please refresh',
								variant: 'error',
							}),
						);
					})
					.finally(() => {
						if (!isMounted) return;
						setIsLoading(false);
					});
			},
			firstLoad ? 0 : 1000,
		);
		return () => {
			isMounted = false;
			clearTimeout(timeout);
		};
		// eslint-disable-next-line
	}, [filters]);

	const goToEditPackage = (p) => {
		history.push({ pathname: `/PackageEdit/${p.id}` });
	};

	const NoPackages = () => {
		dispatch(
			Actions.showMessage({
				message: 'No Result Found',
				variant: 'error',
			}),
		);
	};

	return (
		<FusePageSimple
			classes={{
				root: classes.layoutRoot,
				sidebar: classes.sidebar,
			}}
			content={
				<FuseAnimate animation="transition.slideLeftIn" duration={600}>
					<div className="package-main-page mx-auto pt-52 pb-136">
						<div className="flex justify-between">
							<div>
								<h1 className="font-bold" style={{ fontSize: '20px' }}>
									View your packages{' '}
								</h1>
								<p>View all the package’s information here. </p>
							</div>

							<div className="flex">
								<TextField
									name="search"
									className="mx-8"
									style={{ width: 200, marginTop: 20 }}
									id="search"
									label="Search By Package"
									value={filters.search}
									onChange={handleFilters}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													onClick={() => {
														document.getElementById('search').focus();
													}}
												>
													<img
														alt="search-icon"
														src="assets/images/search-icon.svg"
														height="80%"
														width="80%"
													/>
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
							</div>
						</div>
						<div className="">
							{isLoading ? (
								<div className="flex align-center justify-center">
									<CircularProgress />
								</div>
							) : !packages.length && !firstLoad ? (
								<div>{NoPackages()}</div>
							) : (
								<div className="flex mt-24 flex-col md:flex-row" style={{ gap: 30 }}>
									{packages?.map((p, i) => (
										<div
											key={i}
											className="bg-white rounded relative p-32 flex md:flex-col  gap-32 md:gap-16 flex-1"
										>
											<button
												onClick={() => goToEditPackage(p)}
												className="absolute top-0 right-0"
												style={{ top: '16px', right: '16px' }}
											>
												<img
													className=" cursor-pointer"
													alt="edit-icon"
													src="assets/images/circle-edit.png"
													height="30px"
													width="30px"
												/>
											</button>

											<div className="items-center flex flex-col gap-16">
												<img
													className="mr-6 w-full max-w-96"
													alt="company-face"
													src={p?.image}
												/>

												<div className="pakage-price border-none  md:pb-16">
													${p?.price}/{p?.duration}
												</div>
												<hr
													className="w-full hidden md:block"
													style={{ border: '1px solid #DBDFE9' }}
												/>
											</div>
											<div>
												<div className="package-title">{p?.title}</div>
												{p?.features_meta?.map((f, i) => {
													return (
														<div key={i} className="flex flex-col package-features">
															<span dangerouslySetInnerHTML={{ __html: f?.value }} />
														</div>
													);
												})}
											</div>
										</div>
									))}
								</div>
							)}
						</div>
					</div>
				</FuseAnimate>
			}
			innerScroll
		/>
	);
}
export default Packages;
