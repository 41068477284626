/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, useRef } from 'react';
import FuseAnimate from '@fuse/core/FuseAnimate';
import {
	addCompanySchool,
	getSearchableStateList,
	getCountryList,
	getSearchableCityList,
} from 'app/services/Company/companyService';
import history from '@history';
import { useDispatch } from 'react-redux';
import * as Actions from 'app/store/actions';
import {
	CircularProgress,
	FormHelperText,
	TextField,
	Select,
	InputLabel,
	MenuItem,
	FormControl,
	Button,
	Avatar,
} from '@material-ui/core';
import CustomButton from 'app/customComponents/CustomButton/CustomButton';
import './Company.css';
import { useParams } from 'react-router';
import { uploadFile } from 'app/services/imageUpload/imageUpload';
import FuseScrollbars from '@fuse/core/FuseScrollbars';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getImageUrl, isSameIPVersion } from 'utils/utils';
import CustomCheckbox from 'app/customComponents/CustomCheckbox/CustomCheckbox';
import isIP from 'validator/lib/isIP';
import tzlookup from 'tz-lookup';

const useStyles = makeStyles((theme) => ({
	wrapper: {
		display: 'flex',
		position: 'fixed',
		width: '100%',
		height: '100%',
		flex: '1 1 auto',
	},
	select: {
		'&:before': {
			borderBottom: 'none',
		},
		'&:after': {
			borderBottom: 'none',
		},
		'&:not(.Mui-disabled):hover::before': {
			borderBottom: 'none',
		},
		'& .MuiSelect-select:focus': {
			backgroundColor: 'inherit',
		},
		'& .MuiSvgIcon-root': {
			color: 'inherit',
		},
		color: 'inherit',
		'&:hover': {
			color: 'inherit',
		},
	},
}));
function AddSchool() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const inputRef = useRef(null);
	const [form, setForm] = useState({
		status: 1,
		ip_ranges: [],
		ip_restriction: 0,
		country_code: '',
	});
	const [countries, setCountries] = useState([]);
	const [states, setStates] = useState([]);
	const [city, setCity] = useState([]);
	const [latLong, setLatLong] = useState({ lat: '', long: '' });
	const [cityTimezone, setCityTimeZone] = useState('');
	const [selectedFile, setSelectedFile] = useState(null);
	const [preview, setPreview] = useState(null);
	const [isStateloading, setIsStateloading] = useState(false);
	const [searchStateQuery, setStateSearchQuery] = useState('');
	const [isCityLoading, setIsCityLoading] = useState(false);
	const [searchCityQuery, setSearchCityQuery] = useState('');
	const { id } = useParams();

	const [isReq, setIsReq] = useState(false);
	const [errTxts, setErrTxts] = useState({});

	const handleChange = (ev) => {
		const { name, value } = ev.target;
		if (name === 'country_code') {
			setErrTxts({ ...errTxts, country_code: '' });
			setForm({ ...form, country_code: value, state_id: '', city: '' });
			return;
		}
		if (name === 'state_id') {
			setErrTxts({ ...errTxts, state_id: '' });
			setForm({ ...form, state_id: value, city: '' });
			return;
		}
		setForm({ ...form, [name]: value });
		setErrTxts({ ...errTxts, [name]: '' });
	};

	useEffect(() => {
		if (latLong.lat !== '' && latLong.long !== '') {
			console.log(tzlookup(latLong.lat, latLong.long));
			setCityTimeZone(tzlookup(latLong.lat, latLong.long));
		}
	}, [latLong]);

	useEffect(() => {
		if (!selectedFile) {
			setPreview(null);
			return;
		}

		const objectUrl = URL.createObjectURL(selectedFile);
		setPreview(objectUrl);

		return () => URL.revokeObjectURL(objectUrl);
	}, [selectedFile]);

	const onSelectFile = (e) => {
		if (!e.target.files || e.target.files.length === 0) {
			setSelectedFile(null);
			return;
		}

		setSelectedFile(e.target.files[0]);
	};

	const handleSubmit = (ev) => {
		ev.preventDefault();
		setErrTxts({});
		if (!form.first_name) {
			setErrTxts({ ...errTxts, first_name: 'This field is required' });
			scrollIntoView('first_name');
			return;
		}
		if (form.first_name && /[^a-z A-Z]/.test(form.first_name)) {
			scrollIntoView('first_name');
			setErrTxts({ ...errTxts, first_name: 'Please enter a valid value.' });
			return;
		}
		if (!form.last_name) {
			setErrTxts({ ...errTxts, last_name: 'This field is required' });
			scrollIntoView('last_name');
			return;
		}
		if (form.last_name && /[^a-z A-Z]/.test(form.last_name)) {
			scrollIntoView('last_name');
			setErrTxts({ ...errTxts, last_name: 'Please enter a valid value.' });
			return;
		}
		if (!form.user_email) {
			scrollIntoView('user_email');
			setErrTxts({ ...errTxts, user_email: 'This field is required' });
			return;
		}
		if (!/^\S+@\S+\.\S+$/.test(form.user_email)) {
			scrollIntoView('user_email');
			setErrTxts({ ...errTxts, user_email: 'Please enter valid email' });
			return;
		}
		if (!form.phone_for_admin) {
			setErrTxts({ ...errTxts, phone_for_admin: 'This field is required' });
			return;
		}
		if (form.phone_for_admin) {
			if (
				!Number.isFinite(
					Number(
						form.phone_for_admin
							.split(' ')
							.join('')
							.split('-')
							.join('')
							.split('(')
							.join('')
							.split(')')
							.join(''),
					),
				)
			) {
				setErrTxts({ ...errTxts, phone_for_admin: 'Please enter valid phone number' });
				return;
			}
		}
		if (!form.designation) {
			setErrTxts({ ...errTxts, designation: 'This field is required.' });
			return;
		}
		if (form.designation && /[^a-z A-Z]/.test(form.designation)) {
			setErrTxts({ ...errTxts, designation: 'Please enter a valid value.' });
			return;
		}
		if (!form.school_name) {
			setErrTxts({ ...errTxts, school_name: 'This field is required.' });
			return;
		}
		if (form.school_name && /[^a-z A-Z]/.test(form.school_name)) {
			setErrTxts({ ...errTxts, school_name: 'Please enter a valid value.' });
			return;
		}
		if (!form.school_phone) {
			setErrTxts({ ...errTxts, school_phone: 'This field is required.' });
			return;
		}
		if (form.school_phone) {
			if (
				!Number.isFinite(
					Number(
						form.school_phone
							.split(' ')
							.join('')
							.split('-')
							.join('')
							.split('(')
							.join('')
							.split(')')
							.join(''),
					),
				)
			) {
				setErrTxts({ ...errTxts, school_phone: 'Please enter valid phone number' });
				return;
			}
		}
		if (!form.address) {
			setErrTxts({ ...errTxts, address: 'This field is required.' });
			return;
		}

		if (form.ip_restriction && form.ip_ranges?.length <= 0) {
			setErrTxts({ ...errTxts, ip_restriction: 'Please enter atleast one IP' });
			return;
		}

		if (form.ip_restriction && form.ip_ranges?.length > 0) {
			for (let i = 0; i < form.ip_ranges.length; i++) {
				let iserr = false;
				let err = {};
				if (!isIP(form.ip_ranges[i][0])) {
					err[`ip_ranges.${i}.0`] = 'Please enter valid IP';
					iserr = true;
				} else if (form.ip_ranges[i].length > 1 && !isIP(form.ip_ranges[i][1])) {
					err[`ip_ranges.${i}.1`] = 'Please enter valid IP';
					iserr = true;
				} else if (form.ip_ranges[i].length > 1 && !isSameIPVersion(...form.ip_ranges[i])) {
					err[`ip_ranges.${i}.0`] = 'IPs must be of same version';
					err[`ip_ranges.${i}.1`] = 'IPs must be of same version';
					iserr = true;
				}
				if (iserr) {
					setErrTxts({ ...errTxts, ...err });
					return;
				}
			}
		}

		if (!form.country_code) {
			setErrTxts({ ...errTxts, country_code: 'This field is required.' });
			return;
		}
		if (!form.state_id) {
			setErrTxts({ ...errTxts, state_id: 'This field is required.' });
			return;
		}
		if (!form.city) {
			setErrTxts({ ...errTxts, city: 'This field is required.' });
			return;
		}
		if (!form.zip_code) {
			setErrTxts({ ...errTxts, zip_code: 'This field is required.' });
			return;
		}
		if (typeof form.zip_code !== 'number' && Number.isInteger(form.zip_code)) {
			setErrTxts({ ...errTxts, zip_code: 'Zipcode must be a number' });
			return;
		}

		const data = JSON.parse(JSON.stringify(form));
		data.school_phone = form.school_phone;
		if (data.school_phone) {
			data.school_phone = parseInt(
				data.school_phone.split(' ').join('').split('-').join('').split('(').join('').split(')').join(''),
				10,
			);
		}
		if (!data.ip_restriction) {
			data.ip_ranges = [];
		}
		if (data.state_id) {
			data.state_id = form.state_id;
		}
		if (data.city) {
			data.city = form.city;
			data.timezone = cityTimezone;
		}
		data.company_id = id;
		if (selectedFile) {
			const filename = getImageUrl(selectedFile);
			setIsReq(true);
			uploadFile(selectedFile, filename).then((response) => {
				data.school_profile_image = `${import.meta.env.VITE_S3_BASE_URL}${response}`;
				addCompanySchool(data, cityTimezone)
					.then((resp) => {
						dispatch(
							Actions.showMessage({
								message: resp.data.message,
								autoHideDuration: 1500,
								variant: 'success',
							}),
						);
						history.goBack();
					})
					.catch((err) => {
						setIsReq(false);
						dispatch(
							Actions.showMessage({
								message: 'Failed to add School',
								autoHideDuration: 1500,
								variant: 'error',
							}),
						);
						if (err.response.data.errors) {
							setErrTxts(err.response.data.errors);
						}
					});
			});
			console.log(data, 'data with image');
		} else {
			setIsReq(true);
			addCompanySchool(data, cityTimezone)
				.then((resp) => {
					dispatch(
						Actions.showMessage({
							message: resp.data.message,
							autoHideDuration: 1500,
							variant: 'success',
						}),
					);
					history.goBack();
				})
				.catch((err) => {
					setIsReq(false);
					dispatch(
						Actions.showMessage({
							message: 'Failed to add School',
							autoHideDuration: 1500,
							variant: 'error',
						}),
					);
					if (err.response.data.errors) {
						setErrTxts(err.response.data.errors);
					}
				});
			console.log(data, 'data without image');
		}
	};

	useEffect(() => {
		let isMounted = true;
		const timeout = setTimeout(() => {
			if (!isMounted) return;
			setIsCityLoading(true);
			setForm({ ...form, city: '' });
			setCity([]);
			if (!searchCityQuery && !form.state_id) {
				setForm({ ...form, city: '' });
				setCity([]);
			} else {
				getSearchableCityList(form.state_id, searchCityQuery, 1)
					.then((res) => {
						if (!isMounted) return;
						setForm({ ...form, city: '' });
						setCity(res.data.data);
					})
					.catch((err) => {
						if (!isMounted) return;
						dispatch(
							Actions.showMessage({
								message: 'Failed to get cities.',
								autoHideDuration: 1500,
								variant: 'error',
							}),
						);
					})
					.finally(() => {
						if (!isMounted) return;
						setIsCityLoading(false);
					});
			}
		}, 1000);
		return () => {
			isMounted = false;
			clearTimeout(timeout);
		};
	}, [form.state_id, searchCityQuery]);

	useEffect(() => {
		let isMounted = true;
		const timeout = setTimeout(() => {
			if (!isMounted) return;
			setIsStateloading(true);
			setStates([]);
			setForm({ ...form, city: '' });
			setCity([]);
			if (!searchStateQuery) {
				getSearchableStateList('', '')
					.then((res) => {
						if (!isMounted) return;
						setStates(res.data.data);
						setForm({ ...form, city: '' });
						setCity([]);
					})
					.catch((err) => {
						if (!isMounted) return;
						dispatch(
							Actions.showMessage({
								message: 'Failed to get states.',
								autoHideDuration: 1500,
								variant: 'error',
							}),
						);
					})
					.finally(() => {
						if (!isMounted) return;
						setIsStateloading(false);
					});
			} else {
				getSearchableStateList(searchStateQuery, searchStateQuery ? undefined : 1)
					.then((res) => {
						if (!isMounted) return;
						setStates(res.data.data);
						setForm({ ...form, city: '' });
					})
					.catch((err) => {
						if (!isMounted) return;
						dispatch(
							Actions.showMessage({
								message: 'Failed to get states.',
								autoHideDuration: 1500,
								variant: 'error',
							}),
						);
					})
					.finally(() => {
						if (!isMounted) return;
						setIsStateloading(false);
					});
			}
		}, 1000);
		return () => {
			isMounted = false;
			clearTimeout(timeout);
		};
	}, [dispatch, form.country_code, searchStateQuery]);

	useEffect(() => {
		let isMounted = true;
		getCountryList()
			.then((res) => {
				if (!isMounted) return;
				setCountries(res.data);
			})
			.catch((err) => {
				if (!isMounted) return;
				dispatch(
					Actions.showMessage({
						message: 'Failed to get countries.',
						autoHideDuration: 1500,
						variant: 'error',
					}),
				);
			});
		return () => {
			isMounted = false;
		};
	}, []);

	const scrollIntoView = (name) => {
		const element = document.getElementsByName(name)[0];
		if (element) {
			element.scrollIntoView();
		}
	};

	return (
		<FuseAnimate animation="transition.slideLeftIn" duration={600}>
			<FuseScrollbars className={classes.content} scrollToTopOnRouteChange>
				<div className="add-school-div mx-auto">
					<div className="main-box-form justify-between">
						<div className="flex gap-10">
							<Button onClick={() => history.goBack()}>
								<img
									alt="Go Back"
									className="cursor-pointer"
									src="assets/images/arrow-long.png"
									style={{ width: '25px' }}
								/>
							</Button>
							<h2 className="font-bold" style={{ fontSize: '20px' }}>
								Add School
							</h2>
						</div>
						<div className="box-input">
							<form id="add-school-form" onSubmit={handleSubmit}>
								<h2 className="font-bold" style={{ fontSize: '18px' }}>
									School Admin Info
								</h2>
								<fieldset>
									<div className="flex gap-x-52 xl:gap-x-88 mt-12">
										<div
											onClick={() => inputRef.current.click()}
											className="row-span-2 camera-holder"
											style={{ justifySelf: 'center' }}
										>
											<Avatar src={preview} style={{ width: 120, height: 120 }} />
											<div className="company-pp-overlay">
												<i className="fa fa-2x fa-camera" />
											</div>
											<input
												onChange={onSelectFile}
												type="file"
												name="file"
												id="image"
												className="hidden"
												ref={inputRef}
											/>
										</div>
										<div>
											<div className="grid grid-cols-3 gap-64 lg:gap-x-136">
												<TextField
													error={!!errTxts.first_name}
													helperText={errTxts.first_name}
													name="first_name"
													value={form.first_name}
													onChange={handleChange}
													label="First Name "
												/>
												<TextField
													error={!!errTxts.last_name}
													helperText={errTxts.last_name}
													name="last_name"
													value={form.last_name}
													onChange={handleChange}
													id="lname"
													label="Last Name"
												/>
												<TextField
													error={!!errTxts.user_email}
													helperText={errTxts.user_email}
													name="user_email"
													value={form.user_email}
													onChange={handleChange}
													id="user_email"
													label="Email Address"
												/>
												<TextField
													error={!!errTxts.phone_for_admin}
													helperText={errTxts.phone_for_admin}
													name="phone_for_admin"
													value={form.phone_for_admin}
													onChange={handleChange}
													id="phone_for_admin"
													label="Contact Number"
												/>
												<TextField
													error={!!errTxts.designation}
													helperText={errTxts.designation}
													name="designation"
													value={form.designation}
													onChange={handleChange}
													id="designation"
													label="Designation"
												/>
											</div>
											<h2 className="font-bold mt-32" style={{ fontSize: '18px' }}>
												School Info
											</h2>
											<div className="grid grid-cols-3 gap-64 lg:gap-x-136">
												<TextField
													error={!!errTxts.school_name}
													helperText={errTxts.school_name}
													name="school_name"
													value={form.school_name}
													onChange={handleChange}
													id="school-name"
													label="School Name"
													className="mt-16"
												/>
												<TextField
													error={!!errTxts.school_phone}
													helperText={errTxts.school_phone}
													name="school_phone"
													value={form.school_phone}
													onChange={handleChange}
													id="school-phone"
													label="Contact Number"
													className="mt-16"
												/>
												<TextField
													error={!!errTxts.address}
													helperText={errTxts.address}
													name="address"
													value={form.address}
													onChange={handleChange}
													id="school-address"
													label="Address 1"
													className="mt-16"
												/>
												<TextField
													// error={!!errTxts.street}
													// helperText={errTxts.street}
													name="street"
													value={form.street}
													onChange={handleChange}
													id="school-street"
													label="Address 2"
												/>
												<FormControl error={!!errTxts.country_code}>
													<InputLabel id="country_code_label">Country</InputLabel>
													<Select
														name="country_code"
														value={form.country_code}
														onChange={handleChange}
														labelId="country_code"
														id="country_code"
													>
														{countries.length ? (
															countries.map((country) => {
																return (
																	<MenuItem key={country.id} value={country.code}>
																		<span id={country.code}>{country.name}</span>
																	</MenuItem>
																);
															})
														) : (
															<MenuItem>Loading...</MenuItem>
														)}
													</Select>
													{errTxts.country_code && (
														<FormHelperText>{errTxts.country_code}</FormHelperText>
													)}
												</FormControl>
												<Autocomplete
													id="state-autocomplete"
													name="state_id"
													options={states}
													renderOption={(option) => (
														<div className="flex" style={{ gap: 10 }}>
															<div>{option.name}</div>
														</div>
													)}
													getOptionLabel={(option) => option.name}
													autoComplete={false}
													clearOnBlur={false}
													disableClearable
													loading={isStateloading}
													loadingText="...Loading"
													sx={{ width: '100%' }}
													onChange={(_e, v) => {
														setForm({ ...form, state_id: v?.id || '' });
													}}
													onInputChange={(e, value) => {
														setStateSearchQuery(value);
														if (value === '') {
															setForm({ ...form, state_id: '', city: '' });
														}
													}}
													renderInput={(params) => (
														<TextField
															{...params}
															label="State"
															error={!!errTxts.state_id?.length}
															helperText={errTxts.state_id}
															autoComplete="off"
														/>
													)}
												/>
												<Autocomplete
													id="city-autocomplete"
													name="city"
													options={city}
													renderOption={(option) => (
														<div className="flex" style={{ gap: 10 }}>
															<div>{option.name}</div>
														</div>
													)}
													getOptionLabel={(option) => option.name}
													autoComplete={false}
													clearOnBlur={false}
													disableClearable
													loading={isCityLoading}
													loadingText="...Loading"
													onChange={(_e, v) => {
														const value = JSON.parse(v?.meta);
														setLatLong({
															...latLong,
															lat: value.latitude,
															long: value.longitude,
														});
														setForm({ ...form, city: v?.name || '' });
													}}
													renderInput={(params) => (
														<TextField
															{...params}
															label="City"
															onChange={(e) => {
																setSearchCityQuery(e.target.value);
																if (e.target.value === '') {
																	setForm({ ...form, city: '' });
																}
															}}
															error={!!errTxts.city?.length}
															helperText={errTxts.city}
															autoComplete="off"
														/>
													)}
												/>
												<TextField
													error={!!errTxts.zip_code}
													helperText={errTxts.zip_code}
													name="zip_code"
													value={form.zip_code}
													onChange={handleChange}
													id="school-zipcode"
													label="Zipcode"
												/>
												<FormControl>
													<InputLabel id="state-label">Status</InputLabel>
													<Select
														name="status"
														value={form.status}
														onChange={handleChange}
														labelId="status-label"
														id="school-status"
														placeholder="Status"
													>
														<MenuItem selected value={1}>
															<span id="active">Active</span>
														</MenuItem>
														<MenuItem value={0}>
															<span id="disable">Inactive</span>
														</MenuItem>
													</Select>
												</FormControl>
											</div>
											<div className="flex justify-start gap-64 lg:gap-x-136 my-32">
												<FormControl
													className="flex-grow-1 w-full"
													error={errTxts.ip_restriction}
												>
													<div className="flex items-center justify-between">
														<div>
															<div>
																IP Filtering
																<CustomCheckbox
																	id={`toogle=school-ip-filtering`}
																	onClick={() =>
																		setForm({
																			...form,
																			ip_restriction: form.ip_restriction ? 0 : 1,
																		})
																	}
																	row={{
																		...form,
																		status: form.ip_restriction,
																	}}
																/>
															</div>
															{errTxts.ip_restriction && (
																<FormHelperText>
																	{errTxts.ip_restriction}
																</FormHelperText>
															)}
														</div>
														{!!form.ip_restriction && (
															<CustomButton
																variant="primary"
																height="40"
																width="110px"
																fontSize="14px"
																padding="2px"
															>
																<Select
																	className={classes.select}
																	inputProps={{
																		classes: {
																			root: classes.root,
																			icon: classes.icon,
																		},
																	}}
																	value="Add IP"
																	name="ipadd"
																	id="ip-add-btn"
																	onChange={(e) => {}}
																	// onChange={e => setActiveTab(e.target.value)}
																>
																	<MenuItem
																		className="hidden"
																		value="Add IP"
																		disabled
																	>
																		Add IP
																	</MenuItem>
																	<MenuItem
																		onClick={() => {
																			const newIPRanges = [...form.ip_ranges];
																			newIPRanges.push(['']);
																			setForm({
																				...form,
																				ip_ranges: newIPRanges,
																			});
																			setErrTxts({
																				...errTxts,
																				ip_restriction: '',
																			});
																		}}
																	>
																		Single IP
																	</MenuItem>
																	<MenuItem
																		onClick={() => {
																			const newIPRanges = [...form.ip_ranges];
																			newIPRanges.push(['', '']);
																			setForm({
																				...form,
																				ip_ranges: newIPRanges,
																			});
																			setErrTxts({
																				...errTxts,
																				ip_restriction: '',
																			});
																		}}
																	>
																		IP Range
																	</MenuItem>
																</Select>
															</CustomButton>
														)}
													</div>
												</FormControl>
											</div>
											<div className="flex flex-col my-16 gap-64 lg:gap-x-136">
												{!!form.ip_restriction &&
													form.ip_ranges?.map((ip, ind) => (
														// render a textfield for each ip
														<div className="grid grid-cols-3 gap-32" key={`ip-${ind}`}>
															<TextField
																name={`ip-${ind}-0`}
																label={`IP ${ind + 1}${ip.length > 1 ? ' Start' : ''}`}
																error={!!errTxts[`ip_ranges.${ind}.0`]}
																helperText={errTxts[`ip_ranges.${ind}.0`]}
																value={form.ip_ranges[ind][0]}
																onChange={(e) => {
																	const newIPRanges = [...form.ip_ranges];
																	newIPRanges[ind][0] = e.target.value;
																	setForm({
																		...form,
																		ip_ranges: newIPRanges,
																	});
																	setErrTxts({
																		...errTxts,
																		[`ip_ranges.${ind}.0`]: '',
																	});
																}}
															/>
															{ip.length > 1 && (
																<TextField
																	name={`ip-${ind}-1`}
																	label={`IP ${ind + 1}${
																		ip.length > 1 ? ' End' : ''
																	}`}
																	error={!!errTxts[`ip_ranges.${ind}.1`]}
																	helperText={errTxts[`ip_ranges.${ind}.1`]}
																	value={form.ip_ranges[ind][1]}
																	onChange={(e) => {
																		const newIPRanges = [...form.ip_ranges];
																		newIPRanges[ind][1] = e.target.value;
																		setForm({
																			...form,
																			ip_ranges: newIPRanges,
																		});
																		setErrTxts({
																			...errTxts,
																			[`ip_ranges.${ind}.1`]: '',
																		});
																	}}
																/>
															)}
															<i
																className="fa fa-times cursor-pointer mt-20"
																onClick={() => {
																	const newIPRanges = form.ip_ranges || [];
																	newIPRanges.splice(ind, 1);
																	setForm({
																		...form,
																		ip_ranges: newIPRanges,
																	});
																	setErrTxts({
																		...errTxts,
																		[`ip_ranges.${ind}.0`]: '',
																		[`ip_ranges.${ind}.1`]: '',
																	});
																}}
															/>
														</div>
													))}
											</div>
										</div>
									</div>
								</fieldset>
								{isReq ? (
									<div className="flex align-center justify-center  my-32">
										<CircularProgress />
									</div>
								) : (
									<div className="flex align-center justify-center  my-32">
										<CustomButton
											variant="secondary"
											width={140}
											marginRight="22px"
											onClick={() => {
												history.goBack();
											}}
										>
											Cancel
										</CustomButton>
										<CustomButton
											variant="primary"
											width={140}
											type="submit"
											id="add-school-button"
										>
											Add
										</CustomButton>
									</div>
								)}
							</form>
						</div>
					</div>
				</div>
			</FuseScrollbars>
		</FuseAnimate>
	);
}

export default AddSchool;
