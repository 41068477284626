import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FuseScrollbars from '@fuse/core/FuseScrollbars';
import history from '@history';
import { Avatar, CircularProgress, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CustomButton from 'app/customComponents/CustomButton/CustomButton';
import { getschooldetail } from 'app/services/Company/companyService';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import './Company.css';

const useStyles = makeStyles((theme) => ({
	wrapper: {
		display: 'flex',
		position: 'fixed',
		width: '100%',
		height: '100%',
		flex: '1 1 auto',
	},
}));

function SchoolDetail() {
	const classes = useStyles();
	const [isLoading, setIsLoading] = useState(true);
	const [row, setrow] = useState({});
	const { id } = useParams();
	const inputRef = useRef(null);
	const [preview, setPreview] = useState(null);

	useEffect(() => {
		let isMounted = true;
		setIsLoading(true);
		getschooldetail(id)
			.then((res) => {
				if (!isMounted) return;
				setrow(res?.data);
				setPreview(res?.data?.logo);
				setIsLoading(false);
			})
			.catch((error) => {
				if (!isMounted) return;
				setIsLoading(false);
				console.error('data is invalid', error);
			});
		return () => {
			isMounted = false;
		};
	}, []);

	const goToEditSchool = () => {
		history.push({ pathname: `/EditSchool/${id}` });
	};

	return (
		<FuseScrollbars className={classes.content} scrollToTopOnRouteChange>
			<div className="m-32" style={{ scrollBehavior: 'auto' }}>
				<div className=" flex justify-between ">
					<div className="flex gap-10">
						<span className="">
							<IconButton
								onClick={() => {
									history.goBack();
								}}
							>
								<img
									src="assets/images/arrow-long.png"
									alt="filter"
									width="24px"
									className="backBtn-img"
								/>
							</IconButton>
						</span>
						<h2 className="font-bold">School Detail</h2>
					</div>

					<div className="personal-button flex justify-between">
						<span className="mx-4 ">
							<CustomButton
								variant="secondary"
								height="46"
								width="100px"
								fontSize="15px"
								onClick={goToEditSchool}
							>
								<FontAwesomeIcon icon={faEdit} />
								<span> Edit </span>
							</CustomButton>
						</span>
					</div>
				</div>

				<div className="bg-white rounded mt-28 p-32" style={{ marginBottom: 100 }}>
					<h2 className="font-bold" style={{ fontSize: '18px' }}>
						School Admin Info
					</h2>
					<div className="flex gap-x-52 mt-12">
						{!isLoading && (
							<div
								// onClick={() => inputRef.current.click()}
								className="row-span-2 camera-holder"
								style={{ justifySelf: 'center' }}
							>
								<Avatar src={preview} style={{ width: 120, height: 120 }} />
								{/* <div className="company-pp-overlay">
								<i className="fa fa-2x fa-camera" />
							</div> */}
								<input
									// onChange={onSelectFile}
									type="file"
									name="file"
									id="image"
									className="hidden"
									ref={inputRef}
								/>
							</div>
						)}
						<div className="border-bottom w-full">
							<div className="top-detail">
								<div className="flex-shrink-0 " style={{ marginBottom: '20px' }} />
								{isLoading ? (
									<CircularProgress className="m-auto justtify-center items-center flex" />
								) : (
									<div
										className="grid grid-cols-3 flex-grow"
										style={{ gap: '40px', marginRight: '6.4rem' }}
									>
										<div className="">
											<div style={{ fontSize: 14 }}>First Name</div>
											<div
												style={{ fontSize: 16 }}
												className="font-bold turncate break capitalize"
											>
												{row.super_admin[0].first_name}
											</div>
										</div>
										<div className="">
											<div style={{ fontSize: 14 }}>Last Name </div>
											<div style={{ fontSize: 16 }} className="font-bold turncate break">
												{row.super_admin[0].last_name}
											</div>
										</div>
										<div className="">
											<div style={{ fontSize: 14 }}>Email Address</div>
											<div style={{ fontSize: 16 }} className="font-bold turncate break">
												{row.super_admin[0].email}
											</div>
										</div>
										<div className="">
											<div style={{ fontSize: 14 }}>Contact Number </div>
											<div style={{ fontSize: 16 }} className="font-bold turncate break">
												{row.super_admin[0].phone}
											</div>
										</div>
										<div className="">
											<div style={{ fontSize: 14 }}>Designation </div>
											<div style={{ fontSize: 16 }} className="font-bold turncate break">
												{row.super_admin[0].designation}
											</div>
										</div>
									</div>
								)}
							</div>

							<div className="top-detail " style={{ paddingTop: '61px' }}>
								<div className="flex-shrink-0 " style={{ marginBottom: '20px' }}>
									<span className="totalRooms-heading font-bold">School Info</span>
								</div>
								{isLoading ? (
									<CircularProgress className="m-auto justtify-center items-center flex" />
								) : (
									<div
										className="grid grid-cols-3 flex-grow"
										style={{ gap: '40px', marginRight: '6.4rem' }}
									>
										<div className="">
											<div style={{ fontSize: 14 }}>Organization id</div>
											<div
												style={{ fontSize: 16 }}
												className="font-bold turncate break capitalize"
											>
												{row.otp}
											</div>
										</div>
										<div className="">
											<div style={{ fontSize: 14 }}>School name </div>
											<div style={{ fontSize: 16 }} className="font-bold turncate break">
												{row.name}
											</div>
										</div>
										<div className="">
											<div style={{ fontSize: 14 }}>Contact Number </div>
											<div style={{ fontSize: 16 }} className="font-bold turncate break">
												{row.phone}
											</div>
										</div>
										<div className="">
											<div style={{ fontSize: 14 }}>Address 1</div>
											<div style={{ fontSize: 16 }} className="font-bold turncate break">
												{row.address}
											</div>
										</div>
										<div className="">
											<div style={{ fontSize: 14 }}>Address 2</div>
											<div style={{ fontSize: 16 }} className="font-bold turncate break">
												{row.street}
											</div>
										</div>

										<div className="">
											<div style={{ fontSize: 14 }}>Country </div>
											<div style={{ fontSize: 16 }} className="font-bold turncate break">
												{row.country.code}
											</div>
										</div>
										<div className="">
											<div style={{ fontSize: 14 }}>State </div>
											<div style={{ fontSize: 16 }} className="font-bold turncate break">
												{row.state.name}
											</div>
										</div>
										<div className="">
											<div style={{ fontSize: 14 }}>City </div>
											<div style={{ fontSize: 16 }} className="font-bold turncate break">
												{row.city}
											</div>
										</div>
										<div className="">
											<div style={{ fontSize: 14 }}>Zip code </div>
											<div style={{ fontSize: 16 }} className="font-bold turncate break">
												{row.zip_code}
											</div>
										</div>
										<div className="">
											<div style={{ fontSize: 14 }}>Status </div>
											<div style={{ fontSize: 16 }} className="font-bold turncate break">
												{row.status ? 'Active' : 'Inactive'}
											</div>
										</div>
										<div />
										<div />
										<div className="col-span-3">
											<div style={{ fontSize: 14 }}>IP Filtering </div>
											<div style={{ fontSize: 16 }} className="font-bold turncate break">
												{row.ip_restriction ? 'Enabled' : 'Disabled'}
											</div>
											<ul className="list-inside list-disc">
												{row.ip_restriction
													? row.ip_ranges?.map((ip) => {
															return ip.start === ip.end ? (
																<li>{ip.start}</li>
															) : (
																<li>{ip.start + ' - ' + ip.end}</li>
															);
														})
													: null}
											</ul>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</FuseScrollbars>
	);
}

export default SchoolDetail;
