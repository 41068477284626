import React from 'react';
import './CustomCheckbox.css';

const CustomCheckbox = ({ row, onClick }) => {
	return (
		<span className="cursor-pointer switch-btn">
			<label
				aria-label={`Toggle status for item ${row.id}`}
				className={`switch ${row.status || 'switch-disabled'} `}
				htmlFor={`disable-enable-${row.id}`}
				id={`disable-enable-label-${row.id}`}
			>
				<input
					className="cursor-pointer"
					defaultChecked={!row.status}
					id={`disable-enable-${row.id}`}
					name={`disable-enable-${row.id}`}
					onClick={(e) => onClick(e, row)}
					type="checkbox"
				/>
				<div />
			</label>
		</span>
	);
};

export default CustomCheckbox;
