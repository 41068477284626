import React, { useRef, useState, useEffect } from 'react';
import FuseAnimate from '@fuse/core/FuseAnimate';
import {
	makeStyles,
	Select,
	MenuItem,
	FormControl,
	CircularProgress,
} from '@material-ui/core';
import FusePageSimple from '@fuse/core/FusePageSimple';
import './AdminDashboard.css';

import { useDispatch } from 'react-redux';
import { getdashboarddata } from 'app/services/dashboard/dashBoard';
import * as Actions from 'app/store/actions';
import StatCard from './StatCard';

const useStyles = makeStyles({
	layoutRoot: {},
	sidebar: {
		width: 330,
	},
});

function AdminDashboard() {
	const classes = useStyles();
	const pageLayout = useRef(null);
	// const [currentView, setCurrentView] = useState('week'); // One of week, day, month
	const dispatch = useDispatch();
	const [rows, setRows] = useState([]);

	const [hasMore, setHasMore] = useState(false);
	const [firstLoad, setFirstLoad] = useState(true);
	const [page, setPage] = useState(1);

	const [loading, setLoading] = useState(false);
	const [viewAll, setViewAll] = useState(false);
	const [totalRooms, setTotalRooms] = useState([]);

	const [filters, setFilters] = useState({ type: 'till_date' });
	const [dashBoard, setDashBoard] = useState({});
	const [fetchingMore, setFetchingMore] = useState(false);

	const handleView = (e) => {
		const { value } = e.target;
		setFilters({ type: value });
	};
	useEffect(() => {
		let isMounted = true;
		const timeout = setTimeout(
			() => {
				if (!isMounted) return;
				setLoading(true);
				getdashboarddata(filters.type)
					.then((res) => {
						if (!isMounted) return;
						setFirstLoad(false);
						// setRows(res.data.data || []);
						// setHasMore(res.data.to < res.data.total);
						// setPage(res.data.current_page + 1);
						setDashBoard(res.data);
					})

					.catch((err) => {
						if (!isMounted) return;
						dispatch(
							Actions.showMessage({
								message: 'Failed to fetch data, please refresh',
								variant: 'error',
							}),
						);
					})
					.finally(() => {
						if (!isMounted) return;
						setLoading(false);
					});
			},
			firstLoad ? 0 : 1000,
		);
		return () => {
			isMounted = false;
			clearTimeout(timeout);
		};
		// // eslint-disable-next-line
	}, [filters.type]);

	return (
		<FusePageSimple
			classes={{
				root: classes.layoutRoot,
				sidebar: classes.sidebar,
			}}
			content={
				<div className="pl-72 pr-72 pt-52 pb-136">
					<FuseAnimate animation="transition.slideLeftIn" duration={600}>
						<>
							<div className="flex items-center flex-nowrap justify-between">
								<div className="">
									<h1 className="font-bold m-0" style={{ fontSize: '20px' }}>
										Welcome Admin!
									</h1>
									<h5 className="font-bold" style={{ color: '#6C757D', fontSize: '18px' }}>
										Dashboard
									</h5>
								</div>

								<div>
									<FormControl className="filter-class">
										<Select
											name="filter"
											onChange={handleView}
											value={filters.type}
											id="view"
											// className=''
											style={{ width: 150, gap: 10 }}
											startAdornment={
												<>
													{filters.type === 'daily' && (
														<img
															className="cursor-pointer"
															src="assets/images/day.png"
															alt="Day"
														/>
													)}
													{filters.type === 'weekly' && (
														<img
															className="cursor-pointer"
															src="assets/images/week.png"
															alt="Week"
														/>
													)}
													{filters.type === 'monthly' && (
														<img
															className="cursor-pointer"
															src="assets/images/month.png"
															alt="Month"
														/>
													)}
													{filters.type === 'till_date' && (
														<img
															className="cursor-pointer"
															src="assets/images/day.png"
															alt="Day"
														/>
													)}
												</>
											}
										>
											<MenuItem value="daily">
												<span id="day">Day</span>
											</MenuItem>
											<MenuItem value="weekly">
												<span id="week">Week</span>
											</MenuItem>
											<MenuItem value="monthly">
												<span id="month">Month</span>
											</MenuItem>
											<MenuItem value="till_date">
												<span id="month">Till Date</span>
											</MenuItem>
										</Select>
									</FormControl>
								</div>
							</div>
							<div className="flex flex-row middle-div mt-32">
								{loading ? (
									<div className="flex-grow">
										<CircularProgress className="m-auto justtify-center items-center flex" />
									</div>
								) : (
									<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-16 md:gap-32 lg:gap-64 w-full">
									
										<StatCard
											icon="assets/images/Total-Schools.png"
											value={dashBoard?.schools?.toLocaleString()}
											label="Total Schools"
											valueColor="#6372f4"
										/>

										<StatCard
											icon="assets/images/Revenue.png"
											value={dashBoard?.revenue?.toLocaleString('en-US', {
												style: 'currency',
												currency: 'USD',
											})}
											label="Revenue"
											valueColor="#04C01C"
										/>

										<StatCard
											icon="assets/images/Activities.png"
											value={dashBoard?.activities?.toLocaleString()}
											label="Total no of Activities"
											valueColor="#AA2FFF"
										/>

										{Object.keys(dashBoard.package_status || {}).map((pkg, index) => (
											<StatCard
												key={pkg}
												icon={
													index === 0
														? 'assets/images/Included_package.png'
														: index === 1
															? 'assets/images/premium_package.png'
															: 'assets/images/ultimate_package.png'
												}
												value={dashBoard[pkg]?.toLocaleString()}
												label={pkg}
												valueColor={index === 0 ? '#4DA0ED' : index === 1 ? 'silver' : 'gold'}
											/>
										))}

										<StatCard
											icon="assets/images/Active.png"
											value={dashBoard?.active_subscribers?.toLocaleString()}
											label="Total no of Active Subscribers"
											valueColor="#E845F3"
										/>

										<StatCard
											icon="assets/images/Streaming.png"
											value={dashBoard?.live_streaming}
											label="Session of Live Streaming"
											valueColor="#FD9300"
											isPercentage
										/>
									</div>
								)}
							</div>
						</>
					</FuseAnimate>
				</div>
			}
			innerScroll
			ref={pageLayout}
		/>
	);
}

export default AdminDashboard;
